import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { CollectionDescriptor } from "./CollectionDescriptor";
import { Timestamp } from "./Timestamp";

export const Stay = z.object({
  id: z.string(),
  externalId: z.string().optional(),
  externalPropertyId: z.string().optional(),
  name: z.string().optional(),
  email: z.string().optional(),
  checkinDate: Timestamp.optional(),
  checkoutDate: Timestamp.optional(),
  createdAt: Timestamp,
  roomNumber: z.string().optional(),
});

export type Stay = z.infer<typeof Stay>;

export function isStay(obj: unknown): obj is Stay {
  return isSchema(obj, Stay, "Stay");
}

export const StayCollection = (
  projectId?: string
): CollectionDescriptor<Stay> => ({
  path: `projectConfigs/${projectId}/stays`,
  schema: Stay,
});
